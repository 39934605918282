import type { Options } from "../_model";
import { checkEndpointURL } from "./check-endpoint-url";

export function checkOptions(options: Options) {
  const result = options;
  for (let ep in result?.auth?.endpoints) {
    const el = result.auth.endpoints[ep];
    el.url = checkEndpointURL(el.url);
  }
  if (result.auth) {
    if (!result.auth.defaults) result.auth.defaults = {};
    // @ts-ignore
    result.auth.defaults.unauthorizedStatusCode = result.auth?.defaults?.unauthorizedStatusCode ?? 401;
    // @ts-ignore
    result.auth.defaults.ignoreUnauthorizedAccessStatusCodes = result.auth?.defaults
      ?.ignoreUnauthorizedAccessStatusCodes ?? [500];
  }
  return result;
}
